
import { Component, Mixins } from "vue-property-decorator";
import CompanyMixin, { CompanyTags } from "@/mixins/http/CompanyMixin";
import PageSection from "@/components/Common/PageSection.vue";
import { safeAsync } from "@/utils/AsyncUtil";
import RequestData from "@/store/types/RequestData";
import UsersList from "@/components/User/List.vue";
import { RegisterHttp } from "@/utils/Decorators";
import Company from "@/models/Company";

@Component({
  components: {
    PageSection,
    UsersList,
  },
})
export default class CompanyShow extends Mixins(CompanyMixin) {
  @RegisterHttp(CompanyTags.CompanyIndex) readonly showRequest!: RequestData;

  private item: Company = null;
  private error = false;

  mounted() {
    this.load();
  }

  get filter() {
    return { company: this.item.id };
  }

  get id() {
    return this.$route.params.companyId;
  }

  get loading() {
    return this.showRequest?.loading;
  }

  get meta() {
    return this.showRequest?.meta;
  }

  async load() {
    const [data, errors] = await safeAsync(this.getCompany(this.id));
    if (!errors) {
      this.item = data;
    } else {
      this.error = true;
    }
  }
}
