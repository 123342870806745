
import { Vue, Component, Prop, Ref, Mixins } from "vue-property-decorator";

import VModal from "@monade/vue-components/src/components/VModal.vue";
import UserForm from "@/components/User/Form.vue";
import { User } from "@/models";
import UserMixin, { UserTags } from "@/mixins/http/UserMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast, showToast } from "@/utils/Toast";
import { currentUserStore } from "@/store/typed";
import { UserPayload } from "../../models/User";
import { v1 as uuid } from "uuid";
@Component({
  components: {
    VModal,
    UserForm,
  },
})
export default class UserModal extends Mixins(UserMixin) {
  @Ref() readonly modal!: VModal;
  @Prop() readonly companyId!: string;
  @Prop() readonly organizationId!: string;

  private user: User | null = null;

  private userPayload: UserPayload | null = null;

  get actionName() {
    return this.user ? "Aggiorna" : "Crea";
  }

  get isUserSuperAdmin() {
    return currentUserStore.user ? currentUserStore.user.isSuperAdmin : false;
  }

  private onSubmit() {
    if (this.user) {
      this.httpUpdateUser();
    } else {
      this.httpCreateUser();
    }
  }

  private async httpCreateUser() {
    const [data, errors] = await safeAsync(this.createUser(this.userPayload));

    if (errors) {
      showErrorToast("Errore durante la creazione dell' utente");
      return;
    } else {
      this.reset();
    }

    showToast("Creazione avvenuta con successo!");
    this.$emit("created", data);
    this.hide();
  }

  private async httpUpdateUser() {
    let [data, errors] = await safeAsync(
      this.updateUser(this.user.id, this.userPayload)
    );

    if (errors) {
      showErrorToast("Errore durante la modifica dell' utente");
      return;
    } else {
      this.reset();
    }

    if (!this.isUserSuperAdmin) {
      showToast("Modifica avvenuta con successo!");
      this.$emit("updated", data);
      this.hide();
      return;
    }
  }

  add() {
    this.userPayload = UserPayload.empty();
    this.userPayload.password = uuid();
    if (this.organizationId) {
      this.userPayload.organizationId = this.organizationId;
      this.userPayload.role = "organization_user";
    }

    if (this.companyId) {
      this.userPayload.companyId = this.companyId;
      this.userPayload.role = "company_user";
    }

    this.modal.show();
  }

  edit(user: User) {
    this.user = user;
    if (this.user) {
      this.userPayload = new UserPayload(this.user);
    }
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  reset() {
    this.user = null;
    this.userPayload = null;
  }
}
