
import { UserTags } from "@/mixins/http/UserMixin";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import RoleSelect from "@/components/User/RoleSelect.vue";
import WithErrors from "@/directives/WithErrors";
import OrganizationSelect from "@/components/Organization/Select.vue";
import CompanySelect from "@/components/Company/Select.vue";
import { UserPayload } from "../../models/User";
import { currentUserStore, zoneStore } from "../../store/typed";

@Component({
  directives: {
    WithErrors,
  },
  components: {
    RoleSelect,
    CompanySelect,
    OrganizationSelect,
  },
})
export default class UserForm extends Vue {
  @Prop({ required: true }) readonly value!: UserPayload;

  private user: UserPayload = this.value;

  get httpTag() {
    return UserTags.UserUpsert;
  }

  get isOrganizationUser() {
    return this.user.role === "organization_user";
  }

  get isUpdating() {
    return !!this.user.id;
  }

  get isCompanyUser() {
    return this.user.role === "company_user";
  }

  get isSuperAdmin() {
    return currentUserStore.currentUser.isAdmin || true;
  }

  get realmFilter() {
    return { realm: zoneStore.realm?.id || "" };
  }

  onRoleSelected(role: string) {
    this.user.role = role;
    if (!this.isOrganizationUser) {
      this.user.organizationId = null;
    }
    if (!this.isCompanyUser) {
      this.user.companyId = null;
    }
  }

  @Watch("user", { deep: true })
  onFieldsChanged() {
    this.$emit("input", this.user);
  }
}
