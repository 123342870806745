import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";
import { User } from "@/models";
import { httpStore } from "@/store/typed";
import qs from "qs";

export class UserSelectParams {
  page?: number;
  filter?: {
    role?: string;
    ids?: string[] | string | number[];
    max_role?: string[];
    company?: string | string[];
    organization?: string | string[];
    realm?: string;
    term?: string;
    not?: string | string[] | number[];
  };
}

export enum UserTags {
  UserUpsert = "UserUpsert",
  UserIndex = "UserIndex",
  RuacIndex = "RuacIndex",
  UserDestroy = "UserDestroy",
}

@Component
export default class UserMixin extends Vue {
  createUser(payload: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }): Promise<User> {
    return httpStore.request({
      tag: UserTags.UserUpsert,
      url: `${SD_BASE_API_URL}/users`,
      data: payload,
      method: "POST",
    }) as Promise<User>;
  }

  getUsers(data: any): Promise<User[]> {
    return httpStore.request({
      tag: UserTags.UserIndex,
      url: `${SD_BASE_API_URL}/users`,
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
      method: "GET",
    }) as Promise<User[]>;
  }

  destroyUser(id: string | number): Promise<User> {
    return httpStore.request({
      tag: UserTags.UserDestroy,
      url: `${SD_BASE_API_URL}/users/${id}`,
      method: "DELETE",
    }) as Promise<User>;
  }

  getUser(id: string | number): Promise<User> {
    return httpStore.request({
      tag: UserTags.UserIndex,
      url: `${SD_BASE_API_URL}/users/${id}`,
      method: "GET",
    }) as Promise<User>;
  }

  updateUser(
    id: string,
    payload: { firstName: string; lastName: string }
  ): Promise<User> {
    return httpStore.request({
      tag: UserTags.UserUpsert,
      url: `${SD_BASE_API_URL}/users/${id}`,
      data: payload,
      method: "PUT",
    }) as Promise<User>;
  }

  ruacList(): Promise<User[]> {
    return httpStore.request({
      tag: UserTags.RuacIndex,
      url: `${SD_BASE_API_URL}/admin/users/ruac_index`,
      method: "GET",
    }) as Promise<User[]>;
  }
}
