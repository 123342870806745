
import { Component, Ref, Mixins } from "vue-property-decorator";
import VModal from "@monade/vue-components/src/components/VModal.vue";
import CompanyMixin, { CompanyTags } from "@/mixins/http/CompanyMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { showErrorToast, showToast } from "@/utils/Toast";
import { Company } from "../../models";
import CompanyForm from "../Company/Form.vue";
import { httpStore } from "../../store/typed";
import { CompanyPayload } from "../../models/Company";

@Component({
  components: {
    VModal,
    CompanyForm,
  },
})
export default class CompanyModal extends Mixins(CompanyMixin) {
  @Ref() readonly modal!: VModal;

  private company: Company | null = null;

  private companyPayload: CompanyPayload | null = null;

  get actionName() {
    return this.isUpdating ? "Aggiorna" : "Crea";
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  private onSubmit() {
    if (this.company?.id) {
      this.update();
    } else {
      this.create();
    }
  }

  get isUpdating() {
    return this.company?.id;
  }

  get loading() {
    return (
      httpStore.status[CompanyTags.CompanyCreate]?.loading ||
      httpStore.status[CompanyTags.CompanyUpdate]?.loading
    );
  }

  private async create() {
    const [data, errors] = await safeAsync(
      this.createCompany(this.formDataPayload)
    );

    if (errors) {
      showErrorToast("Errore durante la creazione dell'azienda");
      return;
    }

    showToast("Creazione avvenuta con successo!");
    this.$emit("created", data);
    this.hide();
  }

  private async destroy() {
    const [data, errors] = await safeAsync(
      this.destroyCompany(this.company.id)
    );

    if (errors) {
      showErrorToast("Errore durante la cancellazione della PA");
      return;
    }

    this.$emit("destroyed", data.id);
    this.hide();
  }

  reset() {
    this.company = null;
    this.companyPayload = null;
  }

  get formDataPayload() {
    const data = new FormData();
    data.append("name", this.companyPayload.name);
    if (this.companyPayload.logo) {
      data.append("logo", this.companyPayload.logo.file);
    }
    return data;
  }

  private async update() {
    const [data, errors] = await safeAsync(
      this.updateCompany(this.companyPayload.id, this.formDataPayload)
    );

    if (errors) {
      showErrorToast("Errore durante la modifica dell'azienda");
      return;
    }

    showToast("Aggiornamento avvenuto con successo!");
    this.$emit("updated", data);
    this.hide();
  }

  add(company: Company) {
    this.assignObject(company);
    this.modal.show();
  }

  assignObject(company: Company) {
    this.company = company;
    if (this.company) {
      this.companyPayload = new CompanyPayload(company);
    }
  }

  edit(company: Company) {
    this.assignObject(company);
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }
}
