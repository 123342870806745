
import { Component, Vue } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import EmptyView from "@/components/Common/EmptyView.vue";
import CompaniesList from "@/components/Company/List.vue";

@Component({
  components: {
    PageSection,
    CompaniesList,
    EmptyView,
  },
})
export default class CompanyIndex extends Vue {}
