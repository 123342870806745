import { Company } from "@/models";
import { httpStore } from "@/store/typed";
import { Component, Vue } from "vue-property-decorator";
import { SD_BASE_API_URL } from "@/utils/Constants";
import * as qs from "qs";

export enum CompanyTags {
  CompanyIndex = "CompanyIndex",
  CompanyShow = "CompanyShow",
  CompanyCreate = "CompanyCreate",
  CompanyDestroy = "CompanyDestroy",
  CompanyUpdate = "CompanyUpdate",
}

@Component
export default class CompanyMixin extends Vue {
  getCompanies(page: any, filter: any): Promise<Company[]> {
    const url = `${SD_BASE_API_URL}/companies`;

    const data = {
      page: page,
      filter: filter,
    };
    return httpStore.request({
      tag: CompanyTags.CompanyIndex,
      url: url,
      method: "GET",
      params: data,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }) as Promise<Company[]>;
  }

  getCompany(id: string): Promise<Company> {
    const url = `${SD_BASE_API_URL}/companies/${id}`;

    return httpStore.request({
      tag: CompanyTags.CompanyShow,
      url: url,
      method: "GET",
    }) as Promise<Company>;
  }

  destroyCompany(id: string): Promise<Company> {
    const url = `${SD_BASE_API_URL}/companies/${id}`;

    return httpStore.request({
      tag: CompanyTags.CompanyDestroy,
      url: url,
      method: "DELETE",
    }) as Promise<Company>;
  }

  createCompany(data: FormData): Promise<Company> {
    const url = `${SD_BASE_API_URL}/companies`;

    return httpStore.request({
      tag: CompanyTags.CompanyCreate,
      url: url,
      method: "POST",
      data: data,
    }) as Promise<Company>;
  }

  updateCompany(id: string, data: FormData): Promise<Company> {
    const url = `${SD_BASE_API_URL}/companies/${id}`;

    return httpStore.request({
      tag: CompanyTags.CompanyUpdate,
      url: url,
      method: "PUT",
      data: data,
    }) as Promise<Company>;
  }
}
