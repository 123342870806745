
import { CompanyTags } from "@/mixins/http/CompanyMixin";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import WithErrors from "@/directives/WithErrors";
import { CompanyPayload } from "../../models/Company";
import VFileUploader from "@/components/Common/VFileUploader.vue";

@Component({
  directives: {
    WithErrors,
  },
  components: {
    VFileUploader,
  },
})
export default class CompanyForm extends Vue {
  @Prop({ required: true }) readonly value!: CompanyPayload;

  private item: CompanyPayload = this.value;

  get httpTag() {
    return this.item.id ? CompanyTags.CompanyUpdate : CompanyTags.CompanyCreate;
  }

  get logoUrl() {
    return this.item.logoUrl || (this.item.logo as any)?.url;
  }

  @Watch("item", { deep: true })
  onFieldsChanged() {
    this.$emit("input", this.item);
  }
}
