
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/Common/VSelect.vue";
import { User } from "@/models";

@Component({ components: { VSelect } })
export default class UserRoleSelect extends Vue {
  @Prop({ required: false })
  readonly selected!: any | null;

  @Prop()
  readonly queryKey?: string;

  @Prop()
  readonly label?: string;

  @Prop({ default: "Seleziona un ruolo" })
  readonly placeholder?: string;

  @Prop({ default: false })
  readonly preselect!: boolean;

  @Prop({ default: false })
  readonly actsAsFilter!: boolean;

  @Prop()
  readonly filter!: any;

  options: VSelectOption[] = [];

  private value: any | null = this.selected || null;

  private items: Array<{ name: string; id: string }> = [];
  private term: string | null = null;

  mounted() {
    this.loadItems();
  }

  @Watch("selected")
  onSelectedChanged(selected: string) {
    this.value = selected;
    this.onItemsReady();
  }

  get payload() {
    let data: any = {
      page: {
        size: 100,
      },
    };

    if (this.filter) {
      data.filter = this.filter;
      if (this.term) {
        data.filter.term = this.term;
      }
    } else {
      data.filter = { term: this.term };
    }

    return data;
  }

  get filterDefault() {
    return { id: "all", text: "Tutto" };
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit("input", id);
    this.$emit(
      "inputRaw",
      this.items.find((o: any) => o.id === id)
    );

    if (this.queryKey && this.$route.query[this.queryKey] !== this.value) {
      let query = this.$route.query;
      query[this.queryKey] = this.value;

      this.$router.push({ query });
    }
  }

  onItemsReady() {
    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find(
        (o) => o.id === this.selected.toString()
      )?.id;
    }
  }

  async loadItems() {
    if (this.actsAsFilter) {
      this.options.unshift(this.filterDefault);
    }

    this.items = User.selectableRoles;
    this.options = this.items.map((o: any) => {
      return { id: o.id, text: o.name };
    });
    this.onItemsReady();
  }
}
