
import { Component, Mixins, Prop, Watch, Ref } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import { Company } from "../../models";
import VThrottlerInput from "@/components/Common/VThrottlerInput.vue";
import CompanyMixin, { CompanyTags } from "@/mixins/http/CompanyMixin";
import { safeAsync } from "@/utils/AsyncUtil";
import { RegisterHttp } from "@/utils/Decorators";
import { currentUserStore } from "@/store/typed";
import VPaginator from "@/components/Common/VPaginator.vue";
import RequestData from "@/store/types/RequestData";
import EmptyView from "@/components/Common/EmptyView.vue";
import CompanyModal from "@/components/Company/Modal.vue";
@Component({
  components: {
    PageSection,
    EmptyView,
    VPaginator,
    CompanyModal,
    VThrottlerInput,
  },
})
export default class CompanyIndex extends Mixins(CompanyMixin) {
  @RegisterHttp(CompanyTags.CompanyIndex)
  readonly indexRequest!: RequestData;

  @Prop() readonly realmId: any;

  @Ref() readonly companyModal!: CompanyModal;

  private items: Company[] = [];

  private error = false;

  private term: string | null = null;

  private page = 0;

  get columns() {
    return [
      { name: "logo", label: "Logo" },
      { name: "name", label: "Nome" },
      { name: "actions", label: "" },
    ];
  }

  get currentUser() {
    return currentUserStore.currentUser;
  }

  onCreated() {
    this.onPageChanged(1);
  }

  onUpdated(company: Company) {
    const index = this.items.findIndex((o: Company) => o.id === company.id);
    this.$set(this.items, index, company);
  }

  onDestroyed(id: string) {
    this.items = this.items.filter((o: Company) => o.id !== id);
  }

  get isMobile() {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  onPageChanged(page: number) {
    this.page = page;
    this.load();
  }

  @Watch("term")
  onTermChanged(term: string) {
    this.$nextTick(() => {
      this.load();
    });
  }

  mounted() {
    this.load();
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  get meta() {
    return this.indexRequest?.meta;
  }

  get filter() {
    const data: any = {
      page: { number: this.page },
      filter: { term: this.term },
    };

    if (this.realmId) {
      data.filter.realm = this.realmId;
    }

    return data;
  }

  get defaultCompany() {
    const instance = new Company();
    return instance;
  }

  openCreateModal() {
    (this.companyModal as any).add(this.defaultCompany);
  }

  openEditModal(company: Company) {
    (this.companyModal as any).edit(company);
  }

  goToDetail(company: Company) {
    this.$router.push({
      name: "companies_show",
      params: { companyId: company.id },
    });
  }

  async load() {
    const [data, errors] = await safeAsync(
      this.getCompanies(this.filter.page, this.filter.filter)
    );

    if (!errors) {
      this.items = data;
    } else {
      this.error = true;
    }
  }
}
